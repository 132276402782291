import React, { useEffect, useState } from "react";
import { Button } from "../../../../../../components/button";
import { BanIcon, DownloadIcon } from "lucide-react";
import { Spin } from "antd";

type Props = {};

function ReportButton({ instance }: any) {
  const [userDetails, setUserDetails] = useState(
    JSON.parse(sessionStorage.getItem("activeUser") || "{}")
  );
  const [isAdmin, setIsAdmin] = useState(false);
  useEffect(() => {
    if (userDetails.role === "admin" || userDetails.role === "dev") {
      setIsAdmin(true);
    } else {
      setIsAdmin(true);
    }
  }, [userDetails]);

  return (
    <>
      {isAdmin ? (
        <div className=" flex items-center justify-center">
          {instance.type == "container" || instance.report ? (
            <Button
              onClick={() => {
                Object.assign(document.createElement("a"), {
                  target: "_blank",
                  rel: "noopener noreferrer",
                  href: instance.report,
                }).click();
              }}
            >
              <DownloadIcon />
            </Button>
          ) : (
            <div className="items-center justify-center">
              <BanIcon color="red" />
            </div>
          )}
        </div>
      ) : (
        <div className="items-center justify-center">
          <BanIcon color="red" />
        </div>
      )}
    </>
  );
}

export default ReportButton;
